/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Router from 'vue-router'
import routes from './routers'
import store from '@/store'
import iView from 'view-design'
import { getToken, removeToken ,hasOneOf} from '@/libs/utils'
Vue.use(Router)

const router = new Router({
	routes,
	mode: 'history'
})
const LOGIN_PAGE_NAME = 'login'

router.beforeEach(async (to, from, next) => {
	iView.LoadingBar.start()
	const token = await getToken()
	if (token && !store.state.user.hasGetInfo) {
		store.commit('setStoreToken', token)
	}
	if (!token && to.name !== LOGIN_PAGE_NAME) {
		// 未登录且要跳转的页面不是登录页
		next({
			name: LOGIN_PAGE_NAME // 跳转到登录页
		})
	} else if (!token && to.name === LOGIN_PAGE_NAME) {
		// 未登录且要跳转的页面是登录页
		next() // 跳转
	} else if (token && to.name === LOGIN_PAGE_NAME) {
		// 已经登录且要跳转的页面是登录页
		//部分用户没会员资料页权限
		// if (hasOneOf(['fs_users.search'], store.state.user.access)) {
		// 	next({
		// 		name: 'member-data' // 跳转到会员资料页
		// 	})
		// } else {
		// 	next({
		// 		name: 'intended-member' // 跳转到意向会员页
		// 	})
		// }
		// 已经登录且要跳转的页面是登录页
		next({
			name: 'member-data' // 跳转到会员资料页
		})
	} else {
		if (store.state.user.hasGetInfo) {
		//部分用户没会员资料页权限
		next()
		// if (hasOneOf(['fs_users.search'], store.state.user.access)) {
		// 	next()
		// } else {
		// 	next({
		// 		name: 'intended-member' // 跳转到意向会员页
		// 	})
		// }
		} else {
			store
				.dispatch('getUserInfo')
				.then(() => {
					next()
				})
				.catch((err) => {
					console.log(err)
					// removeToken()

					// next({
					// 	name: LOGIN_PAGE_NAME // 跳转到登录页
					// })
				})
		}
	}
})

router.afterEach(() => {
	iView.LoadingBar.finish()
})

export default router
