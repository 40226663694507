import Vip from '@/api/vip'
import UploadService from '@/api/upload-service'

const state = {
	member_charge: [],
	member_charge_total: 0,
	member_dialog: [],
	member_dialog_total: 0,
	uploadRecords: [],
	members_file_upload_success: false,
	members_file_upload_error: false
}

const mutations = {
	setMemberCharge(state, res) {
		state.member_charge = res.data
		state.member_charge_total = res.meta.total
	},
	setMemberDialog(state, res) {
		state.member_dialog = res.data
		state.member_dialog_total = res.meta.total
	},
	setUploadRecords(state, records) {
		state.uploadRecords = records
	},
	setUploadSuccess(state) {
		state.members_file_upload_success = true
	},
	setUploadError(state) {
		state.members_file_upload_error = true
	},
	clearMessage(state) {
		state.members_file_upload_success = false
		state.members_file_upload_error = false
	}
}

const actions = {
	// 获取会员的交易记录
	getFsUserBusiness({ commit }, { id, params }) {
		return new Promise((resolve, reject) => {
			Vip.getFsUserBusiness(id, params)
				.then((res) => {
					if (res.code === 200) {
						// let result = res.result
						commit('setMemberCharge', res)
						resolve(res)
					}
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	// 获取会员的回访记录
	getFsUserDialog({ commit }, { id, params }) {
		return new Promise((resolve, reject) => {
			Vip.getFsUserDialog(id, params)
				.then((res) => {
					if (res.code === 200) {
						// let result = res.result
						commit('setMemberDialog', res)
						resolve(res)
					}
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	uploadSuccess({ commit }, path) {
		// const date = new Date()
		// let newMembers = { timestamp: date.toLocaleString(), filePath: path }
		UploadService.addFileToQueue(path)
			.then(() => {
				UploadService.getImportedRecordsList(1, 10).then((response) => {
					if (response.code === 200) {
						commit('setUploadRecords', response.data)
					}
				})
			})
			.catch((error) => {
				console.log(error)
			})
		commit('setUploadSuccess')
	},
	uploadError({ commit }) {
		commit('setUploadError')
	},
	clearMessage({ commit }) {
		commit('clearMessage')
	}
}

export default {
	state,
	mutations,
	actions
}
