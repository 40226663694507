import UploadService from '@/api/upload-service'
import NumberService from '@/api/number'

const state = {
	uploadNumbers: [],
	assignments: {},
	selectedGroupMembers: [],
	success: false,
	error: false
}

const mutations = {
	setNumberUploadRecords(state, records) {
		state.uploadNumbers = records
	},
	setAssignments(state, assignments) {
		state.assignments = assignments
	},
	setMembersList(state, selectedGroupMembers) {
		state.selectedGroupMembers = selectedGroupMembers
	},
	distributeAssignments(state, data) {
		for (let i = 0; i < data.managersCount; i++) {
			state.selectedGroupMembers[i].assign_nums += data.perManagerAssignments
		}
	},
	cleanUp(state) {
		state.selectedGroupMembers = []
	},
	setSuccess(state) {
		state.success = true
	},
	setError(state) {
		state.error = true
	},
	clearMessage(state) {
		state.success = false
		state.error = false
	}
}

const actions = {
	numberUploadSuccess({ commit }, path) {
		UploadService.addFileToQueue(path)
			.then(() => {
				UploadService.getImportedRecordsList({ page: 1, per_page: 10 }).then((response) => {
					if (response.code === 200) {
						let importList = []
						for (let item of response.data) {
							if (item.status === 1) {
								importList.push({
									created_at: item.created_at,
									result: item.result,
									manager_name: item.manager.name,
									task_id: item.task_assigns ? item.task_assigns.task_id : null,
									total_tasks: item.task_assigns ? item.task_assigns.count : null
								})
							}
						}
						commit('setNumberUploadRecords', importList)
					}
				})
			})
			.catch(() => {
				commit('setError')
			})
	},
	getAssignments({ commit }, task_id) {
		NumberService.getAssignmentsList({ task_id: task_id })
			.then((response) => {
				let groups = []
				let group_members = []
				for (let item of response.data.nodes) {
					groups.push({ id: item.id, name: item.name })
					for (let manager in item.team_managers) {
						group_members.push({
							group_id: item.id,
							manager_id: item.team_managers[manager].id,
							manager_name: item.team_managers[manager].name,
							assign_nums: 0
						})
					}
				}
				commit('setAssignments', { groups, group_members })
			})
			.catch(() => {
				commit('setError')
			})
	},
	listGroupMembers({ commit }, group_id) {
		commit(
			'setMembersList',
			state.assignments.group_members.filter((member) => member.group_id == group_id)
		)
	},
	distributeAssignments({ commit }, data) {
		commit('distributeAssignments', data)
	},
	reassignTasks({ commit }, data) {
		NumberService.reassignTasksToManagers({ task_id: data.task_id, assign: data.membersList })
			.then((response) => {
				if (response.code == 200) {
					commit('cleanUp')
					commit('setSuccess')
				}
			})
			.catch(() => {
				commit('setError')
			})
	},
	cleanUp({ commit }) {
		commit('cleanUp')
	},
	uploadError({ commit }) {
		commit('setError')
	},
	clearMessage({ commit }) {
		commit('clearMessage')
	}
}

export default {
	state,
	mutations,
	actions
}
