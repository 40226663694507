import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { localRead } from '@/libs/utils'
// import customZhCn from './lang/zh-CN.yaml'

import customEnUs from './lang/en-US.yaml'
import zhCnLocale from 'view-design/dist/locale/zh-CN'
import enUsLocale from 'view-design/dist/locale/en-US'
import { locale } from 'view-design'
Vue.use(VueI18n)

// 自动根据浏览器系统语言设置语言
// const navLang = navigator.language
// const localLang = navLang === "zh-CN" || navLang === "en-US" ? navLang : false
// let lang = localLang || localRead("local") || "zh-CN"
let customZhCn = {}
Object.keys(customEnUs).forEach((item) => {
	customZhCn[item] = item
})
let lang = localRead('local') || 'zh-CN'

if (lang === 'en-US') {
	locale(enUsLocale)
}
Vue.config.lang = lang
// vue-i18n 6.x+写法
// Vue.locale = () => {}
const messages = {
	'zh-CN': {
		...zhCnLocale,
		...customZhCn
	},
	'en-US': {
		...enUsLocale,
		...customEnUs
	}
}
const i18n = new VueI18n({
	locale: lang,
	messages,
	silentTranslationWarn: true
})
export default i18n
