import fetch from '@/libs/fetch'

export default class User {
	static login(params) {
		return fetch.post('/managers/login', params)
	}
	static getUserInfo() {
		return fetch.get('/managers/me')
	}
	/**
	 * @description 获取OTP和IP白名单功能的状态
	 * @param {string} key key=otp为OTP配置，key=ip为IP是否启用配置
	 */
	static getSystemConfig(key) {
		return fetch.get(`/configs/${key}`)
	}
	/**
	 * @description 获取OTP和IP白名单功能的状态
	 * @param {string} key key=otp为OTP配置，key=ip为IP是否启用配置
	 * @param {string} value 0-关闭，1-开启
	 */
	static updateSystemConfig(key, params) {
		return fetch.patch(`/configs/${key}`, params)
	}
	/**
	 * @description 检测OTP是否有效
	 */
	static checkOtpEffect() {
		return fetch.get('/configs/fs_otp')
	}
	/**
	 * @description 设置OTP
	 * @param {string} otp 要设置的otp值
	 */
	static setOtpValue(params) {
		return fetch.patch('/configs/fs_otp', params)
	}
	/**
	 * @description 获取当前的电话和短信配置
	 */
	static getPortSetAll() {
		return fetch.get('/settings')
	}
	/**
	 * @description 获取当前的电话和短信配置
	 * @param {string} key=dial为电话配置，key=sms为短信配置
	 * @param {string} title 配置名称
	 * @param {string} account 账号
	 * @param {string} access_token access_token
	 * @param {string} url key=sms为短信配置时
	 */
	static updatePortSet(params, key) {
		return fetch.patch(`/settings/${key}`, params)
	}
	/**
	 *@description 获取白名单列表
	 *@param {number} page 页码
	 *@param {number} per_page 条数
	 */
	static getUserIpsList(params) {
		return fetch.get('/ips', {
			params
		})
	}
	/**
	 *@description 新增白名单
	 *@param {string} ip ip地址
	 *@param {string} description 描述
	 */
	static addUserIp(params) {
		return fetch.post('/ips', params)
	}
	/**
	 *@description 修改白名单
	 *@param {number} id 白名单ip的id
	 *@param {string} ip ip地址
	 *@param {string} description 描述
	 */
	static updateUserIp(id, params) {
		return fetch.patch(`/ips/${id}`, params)
	}
	/**
	 *@description 删除白名单
	 *@param {number} id 白名单ip的id
	 */
	static deleteUserIp(id) {
		return fetch.delete(`/ips/${id}`)
	}
	/**
	 *@description 用户的下载列表
	 *@param {number} page 页码
	 *@param {number} per_page 条数
	 */
	static queryDownloadList(params) {
		return fetch.get('/downloads', {
			params
		})
	}
	/**
	 *@description 重启下载任务
	 *@param {number} download_id 下载任务id
	 *@param {number} status 0-待生成，1-生成导出成功，2-生成导出失败，3-生成导出条件改变
	 */
	static changeDownStatus(id, params) {
		return fetch.patch(`/downloads/${id}`, params)
	}
	/**
	 *@description 修改密码
	 *@param {string} current_pass 旧密码
	 *@param {string} password 新密码
	 *@param {string} password_confirmation 确认新密码
	 */
	static updateUserPassword(params) {
		return fetch.post('/managers/password', params)
	}
	/**
	 * @description 获取爬虫列表
	 */
	static getReptileList(params) {
		return fetch.get('/crawls/get_status', params)
	}
	/**
	 * @description 更新otp值
	 * @param {String} data.opt
	 */
	static modifyOtpValue(data) {
		return fetch.post('/crawls/update_otp', data)
	}
}
