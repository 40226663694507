<template>
	<div class="call">
		<!-- <audio id="localAudio"  :src="ringCall"></audio> -->
		<div class="call-status">
			<div>
				<span :class="{ successColor: loginStatus, errorColor: !loginStatus }">{{ loginStatus ? $t('在线') : $t('离线') }}</span>
			</div>
			<!-- <div class="call-status-main"> -->
			<div class="call-status-main" v-if="showCallStatus">
				<div>
					<span>{{ callStatus === 1 ? $t('正在呼叫') : $t('通话中') }}</span>
					<span style="margin-left: 10px" v-if="callStatus === 2">{{ callDuration | setSecondToFormat }}</span>
				</div>
				<div>
					<span>{{ callUserNow.username }}</span>
				</div>
				<div>
					<span>{{ callUserNow.true_name }}</span>
				</div>
				<div>
					<span>{{ callUserNow.phone_num | encryptionphone }}</span>
				</div>
				<div class="hangup">
					<img class="cur" :src="HangUp" :alt="$t('挂断')" @click="hangup" />
				</div>
			</div>

			<div class="call-status-main" v-if="!showCallStatus && loginStatus">
				<div>
					<span class="successColor">{{ $t('空闲') }}</span>
				</div>
				<div>
					<span>{{ $t('等待拨打') }}</span>
				</div>
			</div>
			<div v-if="groupCallAccess" class="btn-box">
				<RadioGroup @on-change="groupCallStatus()" v-model="currentState" type="button" button-style="solid">
					<Radio label="busy">{{ $t('置忙') }}</Radio>
					<Radio label="idel">{{ $t('置闲') }} </Radio>
					<Radio label="offline">{{ $t('离线') }}</Radio>
				</RadioGroup>
			</div>
		</div>
		<div class="call-main">
			<div class="call-main-action">
				<div @click="changeTab(1)" :class="{ primaryColor: currentTab === 1 }">
					<span>{{ $t('拨打列表') }}</span>
				</div>
				<span class="call-main-action-line"></span>
				<div @click="changeTab(2)" :class="{ primaryColor: currentTab === 2 }">
					<span>{{ $t('历史记录') }}</span>
				</div>
			</div>
			<div class="call-main-content">
				<!-- 拨打任务 -->
				<div class="call-main-content-queue" v-show="currentTab === 1">
					<div class="call-main-content-queue-top">
						<div>
							<span>
								{{ $t('拨打任务') }}
								({{ callData.length }})
							</span>
						</div>
						<!-- <div @click="handleStartTask"><span>开始</span></div> -->
						<div v-if="loginStatus">
							<Icon class="cur" v-if="playOrPause" type="ios-pause" size="24" :title="$t('暂停')" @click="handlePasue" />
							<Icon class="cur" v-else type="ios-play" size="24" :title="$t('开始')" @click="handleStartTask" />
						</div>
					</div>
					<ul class="call-main-content-queue-list">
						<li v-for="(item, index) in callData" :key="index">
							<!-- 会员信息 -->
							<div class="member-info">
								<span style="width: 55px">{{ item.username }}</span>
								<span style="width: 45px; text-align: center; margin: 0 5px">{{ item.true_name }}</span>
								<span style="width: 70px">{{ item.phone_num | encryptionphone }}</span>
							</div>
							<!-- 操作按钮 -->
							<div class="member-action">
								<Icon type="md-arrow-up" size="18" :title="$t('向上移')" class="cur" @click="taskMoveUp(index)" v-show="index && index !== currentIndex" />
								<Icon
									type="md-arrow-down"
									size="18"
									title="向下移"
									class="cur"
									@click="taskMoveDown(index)"
									v-show="index < callData.length - 1 && index !== currentIndex"
								/>
								<Icon type="md-close" class="cur" size="18" :title="$t('移除')" @click="handleDelete(index)" v-show="index !== currentIndex" />
								<!-- <span>{{index + 1}}</span> -->
							</div>
						</li>
					</ul>
				</div>
				<!-- 历史记录 -->
				<div class="call-main-content-history" v-show="currentTab === 2">
					<ul class="call-main-content-history-list">
						<li v-for="(item, index) in callRecord" :key="index" style="margin-bottom: 16px">
							<!-- 会员信息 -->
							<div class="member-info">
								<span style="width: 60px">{{ item.username }}</span>
								<span style="width: 50px; text-align: center; margin: 0 5px">{{ item.true_name }}</span>
								<span style="width: 80px">{{ item.phone_num | encryptionphone }}</span>
							</div>
							<!-- 通话信息 -->
							<div>
								<span>时长：</span>
								<span style="margin-right: 18px">{{ item.duration }}s</span>
								<span>{{ $t('拨打时间') }} :</span>
								<span>{{ item.startTime }}</span>
							</div>
							<div>
								<span>{{ $t('通话标记') }} :</span
								><span :class="{ successColor: item.dial_status === 1, errorColor: item.dial_status !== 1 }">{{ dialToText(item.dial_status) }}</span>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<Modal v-model="dialModal" width="400" :closable="false" :mask-closable="false">
			<div class="dialModal-info">
				<div style="font-size: 14px">
					<p>
						<span>{{ $t('刚刚结束的呼叫') }}</span>
						<span style="margin-left: 10px">"{{ prevCallTask.username }}</span
						><span style="margin: 0 10px">({{ prevCallTask.true_name }})"</span><span>{{ $t('通话时间较短') }}</span>
					</p>
					<p style="margin-top: 10px">
						<span>{{ $t('请选择标记') }}</span>
					</p>
				</div>
				<div class="dial-list">
					<span class="dial-status" v-for="item in dialStatus" :key="item.id" @click="closeDialModal(item.id)">{{ item.name }}</span>
				</div>
			</div>
			<div slot="footer" class="dialModal-footer">
				<div>
					<Button @click="closeDialModal(0)">{{ $t('关闭') }}</Button>
				</div>
				<div class="dialModal-footer-time">
					<span>{{ dialModalDuration }}{{ $t('秒后自动关闭') }}</span>
				</div>
			</div>
		</Modal>
		<audio id="audio-remote" autoplay></audio>
		<audio id="local-audio" ref="localAudio" :src="ringCall" loop></audio>
	</div>
</template>
<script>
import JsSIP from 'jssip'
import Vip from '@/api/vip'
import GroupCall from '@/api/group-call'
import HangUp from '../../assets/images/hangup.png'
import { setSecondToFormat } from '@/libs/utils'
import ringCall from '@/assets/voice/ring.mp3'
import { throttle } from '@/libs/utils'
import { NEW_CALL_MODE_PLATFORM, GROUP_CALL } from '@/libs/constants'
import qs from 'qs'
JsSIP.C.SESSION_EXPIRES = 600
JsSIP.C.MIN_SESSION_EXPIRES = 120
JsSIP.debug.disable('JsSIP:*')

export default {
	data() {
		return {
			ringCall,
			HangUp,
			dialModal: false,
			currentTab: 1,
			callPhone: null,
			callSession: null,
			loginStatus: false,
			callStatus: 1, // 1 正在呼叫  2 通话中
			currentIndex: null,
			callUserNow: {}, // 正在呼叫的会员信息
			playOrPause: false, // false 暂停  true 开始
			callTime: null,
			callDuration: 0,
			callTimer: null, // 计时定时器
			dialModalTimer: null, // 弹框关闭倒计时 定时器时器
			dialModalDuration: 0,
			prevCallTask: {},
			dialStatus: [
				{ id: 1, name: this.$t('成功回访') },
				{ id: 2, name: this.$t('停机') },
				{ id: 3, name: this.$t('关机') },
				{ id: 4, name: this.$t('空号') },
				{ id: 5, name: this.$t('无人接听') },
				{ id: 6, name: this.$t('直接挂掉') },
				{ id: 7, name: this.$t('用户正忙') },
				{ id: 8, name: this.$t('不在服务区') },
				{ id: 9, name: this.$t('不是本人') }
			],
			callRecord: [], // 拨打历史记录
			showCallStatus: false, // 拨打状态
			currentState: '' //群呼状态
		}
	},
	computed: {
		callData() {
			return this.$store.state.call.callData
		},
		sipData() {
			return this.$store.state.user.sipInfo
		},
		userName() {
			return this.$store.state.user.name
		},
		newCallAccess() {
			const code = localStorage.getItem('code')
			return NEW_CALL_MODE_PLATFORM.includes(code)
		},
		// new call type
		groupCallAccess() {
			const code = localStorage.getItem('code')

			return GROUP_CALL.includes(code)
		}
	},
	created() {
		this.login()
	},
	filters: {
		setSecondToFormat: function (value) {
			return setSecondToFormat(value)
		},
		encryptionphone: function (value) {
			const code = localStorage.getItem('code')
			if (!value) {
				return ''
			} else {
				if (code === '00033') {
					return '******' + value.substr(7)
				} else {
					return value.substr(0, 3) + '****' + value.substr(7)
				}
			}
		}
	},
	methods: {
		dialToText(value) {
			if (!value) {
				return this.$t('未标记')
			} else {
				let dialMap = {
					0: this.$t('未标记'),
					1: this.$t('成功回访'),
					2: this.$t('停机'),
					3: this.$t('关机'),
					4: this.$t('空号'),
					5: this.$t('无人接听'),
					6: this.$t('直接挂掉'),
					7: this.$t('用户正忙'),
					8: this.$t('不在服务区'),
					9: this.$t('不是本人')
				}
				return dialMap[String(value)]
			}
		},
		// 任务向下移
		taskMoveDown(index) {
			let arr = JSON.parse(JSON.stringify(this.callData))
			arr.splice(index + 1, 1, ...arr.splice(index, 1, arr[index + 1]))
			this.$store.commit('SET_CALL_DATA', arr)
		},
		// 任务向上移
		taskMoveUp(index) {
			let arr = JSON.parse(JSON.stringify(this.callData))
			arr.splice(index - 1, 1, ...arr.splice(index, 1, arr[index - 1]))
			this.$store.commit('SET_CALL_DATA', arr)
		},
		// 移出队列
		handleDelete(index) {
			this.$store.commit('DELETE_CALL_DATA', index)
		},
		openDialModal: throttle(function () {
			// 用户手动标记
			if (this.dialModal) {
				clearTimeout(this.dialModalTimer)
				this.dialModal = false
			}
			this.dialModal = true
			this.dialModalDuration = 6
			this.dialModalTimer = setInterval(() => {
				this.dialModalDuration--
				if (this.dialModalDuration === 0) {
					// 倒计时完毕 自动关闭
					this.closeDialModal()
				}
			}, 1000)
		}, 800),
		// 关闭弹框并标记
		closeDialModal: throttle(function (status = 0) {
			clearInterval(this.dialModalTimer)
			this.dialModal = false
			this.generateHistory(status)
			this.nextCall()
		}, 800),
		changeTab(index) {
			this.currentTab = index
		},
		// jssip login
		login() {
			const socket = new JsSIP.WebSocketInterface(this.sipData.sip_ws)
			const configuration = {
				sockets: [socket],
				realm: this.sipData.sip_ip,
				ws_servers: this.sipData.sip_ws,
				display_name: this.userName,
				uri: `sip:${this.sipData.sip_id}@${this.sipData.sip_ip}`,
				password: this.sipData.sip_pw,
				register: true
			}
			this.callPhone = new JsSIP.UA(configuration)
			this.callPhone.start()
			this.uaListenEvents()
		},
		uaListenEvents() {
			this.callPhone.on('connecting', () => {
				console.log('---------connecting')
				this.loginStatus = false
			})
			this.callPhone.on('connected', () => {
				console.log('---------connected')

				this.loginStatus = true
			})
			this.callPhone.on('disconnected', () => {
				console.log('---------disconnected')
				if (!this.newCallAccess) {
					this.$Notice.error({
						title: this.$t('提示'),
						desc: 'SIP 连接失败'
					})
				}

				this.loginStatus = false
			})
			// 新通话
			this.callPhone.on('newRTCSession', (e) => {
				console.log('日志:---------------newRTCSession')
				const { session } = e
				this.callSession = session
				session.on('connecting', () => {
					console.log('日志:---------------session connecting')
				})
				session.on('accepted', () => {
					console.log('日志:---------------session accepted')
				})
				session.on('confirmed', () => {
					console.log('日志:---------------session confirmed')
					this.$refs.localAudio.pause()
					const localAudio = document.getElementById('audio-remote')
					const stream = new MediaStream()
					const receivers = session.connection?.getReceivers()
					if (receivers) {
						receivers.forEach((receiver) => {
							stream.addTrack(receiver.track)
						})
						localAudio.srcObject = stream
						localAudio.play()
					}
				})
				session.on('ended', () => {
					console.log('日志:---------------session ended')
				})
				session.on('failed', () => {
					console.log('日志:---------------session failed')
					this.callSession = null
				})
			})
		},
		// 拨打电话
		call({ call_prefix, call_type, phone }) {
			this.callTime = this.$dayjs().format('YYYY-MM-DD HH:mm:ss')
			console.log(`开始拨打==========>${this.callTime}`)
			this.showCallStatus = true
			clearTimeout(this.callTimer)
			this.callDuration = 0 // 将计时重置
			this.currentIndex = 0
			const eventHandlers = {
				progress: () => {
					console.log('日志:---------------正在呼叫')
					this.callStatus = 1
					this.$refs.localAudio.play()
				},
				failed: (e) => {
					console.log('日志:---------------通话失败')
					console.log(e)
					this.$refs.localAudio.pause()
					this.showCallStatus = false
					this.prevCallTask = JSON.parse(JSON.stringify(this.callUserNow))
					this.openDialModal()
				},
				confirmed: () => {
					console.log('日志:---------------确认通话')
					this.$refs.localAudio.pause()
					this.callStatus = 2
					const vm = this
					setTimeout(function fn() {
						// 执行业务代码，执行完成后，设定一个任务
						vm.callDuration++
						vm.callTimer = setTimeout(fn, 1000)
					}, 1000)
				},
				ended: async () => {
					console.log('日志:---------------通话结束') // 通话结束
					this.showCallStatus = false
					this.$refs.localAudio.pause()
					this.prevCallTask = JSON.parse(JSON.stringify(this.callUserNow))
					if (this.callTimer) {
						clearTimeout(this.callTimer) // 清除计时定时器
					}
					if (this.callDuration > 30) {
						// 通话时间超过30S,自动标记成功回访
						this.generateHistory(1)
						this.nextCall()
					} else {
						this.openDialModal()
					}
				}
			}
			const options = {
				eventHandlers: eventHandlers,
				extraHeaders: ['X-Foo: foo', 'X-Bar: bar'],
				mediaConstraints: { audio: true, video: false },
				pcConfig: {}
			}
			const phoneData = `sip:${call_prefix}${call_type}${phone}@${this.sipData.sip_ip}`
			this.callPhone.call(phoneData, options)
		},
		// 暂停拨打任务
		handlePasue() {
			this.playOrPause = false // 任务暂停
		},
		// 开始拨打任务
		handleStartTask() {
			if (!this.callData.length) {
				this.$Message.error({
					content: this.$t('请先添加拨打任务!'),
					duration: 3
				})
				return
			}
			this.playOrPause = true // 任务开始
			this.callUserNow = this.callData[0]
			this.call(this.callData[0])
		},
		// 挂断
		hangup() {
			if (this.callSession !== null) {
				console.log('挂断========>terminate')
				this.callSession.terminate()
			} else {
				console.log('挂断========>terminateSessions')
				this.callPhone.terminateSessions()
			}
		},
		nextCall() {
			// 先将上一通电话记录
			this.$store.commit('DELETE_CALL_DATA', 0) // 然后删除
			if (this.callData.length && this.playOrPause) {
				// 还有任务可继续拨打 并且 任务是进行状态
				this.callUserNow = this.callData[0]
				this.call(this.callData[0])
			} else {
				// 任务拨打完毕
				this.playOrPause = false
			}
		},
		// 生成历史记录
		generateHistory(status) {
			if (status) {
				this.addCallStatusToFsUser(status)
			}
			let record = Object.assign({}, { ...this.prevCallTask, duration: this.callDuration, startTime: this.callTime, dial_status: status })
			this.callRecord.push(record)
		},
		// 通话标记
		async addCallStatusToFsUser(status) {
			let params = { content: status }
			const res = await Vip.addCallStatusToFsUser(this.prevCallTask.user_id, params)
			if (res.code === 200) {
				return status
			} else {
				return 0
			}
		},
		//群呼状态
		async groupCallStatus() {
			// let params = { param: 1, name: 'Irfan' }
			let params = { param: 1, name: this.userName }

			const data = qs.stringify({ ...params })
			const res = await GroupCall.queryAgents(data)
			if (res.data.result.error != 0) {
				this.currentState = ''
				this.$Message.error(res.data.result.msg)
				return
			}
			if (res.data.data.rows.length == 0) {
				this.currentState = ''
				this.$Message.error(this.$t('未查询到坐席用户名'))
				return
			}

			let params2 = { ids: JSON.stringify([res.data.data.monitor[0].key]), action: this.currentState, param: 1 }
			const data2 = qs.stringify({ ...params2 })
			const res2 = await GroupCall.okccUpdateStatus(data2)
			if (res2.data.result.error != 0) {
				this.currentState = ''

				this.$Message.error(res2.data.result.msg)
				return
			}
		}
	}
}
</script>
<style lang="less" scoped>
.call {
	width: 100%;
	height: 100%;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	&-status {
		width: 100%;
		height: 230px;
		padding: 8px;
		display: flex;
		flex-direction: column;
		&-main {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;
		}
	}
	&-main {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		&-action {
			display: flex;
			align-items: center;
			width: 100%;
			height: 40px;
			border-top: 1px solid #ddd;
			border-bottom: 1px solid #ddd;
			div {
				flex: 1;
				text-align: center;
				line-height: 40px;
				font-size: 14px;
				cursor: pointer;
			}
			&-line {
				display: block;
				width: 1px;
				height: 20px;
				background: #ddd;
			}
		}
		&-content {
			flex: 1;
			overflow: hidden;
			&-queue {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				&-top {
					display: flex;
					justify-content: space-between;
					padding: 16px 10px;
				}
				&-list {
					flex: 1;
					padding: 0 10px;
					overflow-y: auto;
					li {
						width: 100%;
						height: 40px;
						display: flex;
						justify-content: space-between;
						.member-info {
							flex: 1;
							span {
								display: inline-block;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
						}
					}
				}
				&-list::-webkit-scrollbar {
					width: 8px;
					height: 8px;
				}

				&-list::-webkit-scrollbar-track {
					background-color: #f8f8f8;
					-webkit-border-radius: 2em;
					-moz-border-radius: 2em;
					border-radius: 2em;
				}

				&-list::-webkit-scrollbar-thumb {
					background-color: #dddddd;
					-webkit-border-radius: 2em;
					-moz-border-radius: 2em;
					border-radius: 2em;
				}
			}
			&-history {
				width: 100%;
				height: 100%;
				padding: 10px;
				overflow-y: auto;
			}
			&-history::-webkit-scrollbar {
				width: 8px;
				height: 8px;
			}

			&-history::-webkit-scrollbar-track {
				background-color: #f8f8f8;
				-webkit-border-radius: 2em;
				-moz-border-radius: 2em;
				border-radius: 2em;
			}

			&-history::-webkit-scrollbar-thumb {
				background-color: #dddddd;
				-webkit-border-radius: 2em;
				-moz-border-radius: 2em;
				border-radius: 2em;
			}
		}
	}
}

.dial-list {
	display: inline-flex;
	justify-content: center;
	flex-wrap: wrap;
}
.dial-status {
	margin: 15px;
	color: #2d8cf0;
	cursor: pointer;
}
.dialModal-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-top: 10px;
}
.dialModal-footer {
	display: flex;
	justify-content: center;
	position: relative;
	&-time {
		position: absolute;
		top: 7px;
		left: 220px;
	}
}
.btn-box {
	margin-bottom: 5px;
	text-align: center;
}
</style>
