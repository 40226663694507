const TOKEN_KEY = 'token'
import Cookies from 'js-cookie'
export const setToken = (token, expires) => {
	var millisecond = new Date().getTime()
	var expiresTime = new Date(millisecond + expires * 1000)

	Cookies.set(TOKEN_KEY, token, { expires: expiresTime })
}

export const getToken = () => {
	return Cookies.get(TOKEN_KEY)
}

export const removeToken = () => {
	Cookies.remove(TOKEN_KEY)
}

// 函数防抖
let timer = null

export const debounce = (fn, wait) => {
	if (timer !== null) clearTimeout(timer)
	timer = setTimeout(fn, wait)
}

// 节流
export const throttle = (func, wait) => {
	var context, args
	var previous = 0

	return function () {
		var now = +new Date()
		context = this
		args = arguments
		if (now - previous > wait) {
			func.apply(context, args)
			previous = now
		}
	}
}

export const breadthQuery = (tree, id) => {
	var stark = []
	stark = stark.concat(tree)
	while (stark.length) {
		var temp = stark.shift()
		if (temp.children) {
			stark = stark.concat(temp.children)
		}
		if (temp.id === id) {
			return temp
		}
	}
}

/**
 * @param {Array} target 目标数组
 * @param {Array} arr 需要查询的数组
 * @description 判断要查询的数组是否至少有一个元素包含在目标数组中
 */
export const hasOneOf = (targetarr, arr) => {
	return targetarr.some((_) => arr.indexOf(_) > -1)
}

/**
 * @param {Array} list 目标数组
 * @param {string} name 需要查找的name值
 * @description 通过name查找数组中对应值得id
 */
export const findNameToId = (name, list) => {
	let arr = list.find((_) => {
		return _.name === name
	})
	return arr.id
}

export const findIdToName = (id, list) => {
	let arr = list.find((_) => {
		return _.id === id
	})
	return arr.name
}
export const findNameToValue = (name, list) => {
	let arr = list.find((_) => {
		return _.name === name
	})
	return arr.value
}
export const accSub = (arg1, arg2) => {
	let r1, r2, m, n
	try {
		r1 = arg1.toString().split('.')[1].length
	} catch (e) {
		r1 = 0
	}
	try {
		r2 = arg2.toString().split('.')[1].length
	} catch (e) {
		r2 = 0
	}
	m = Math.pow(10, Math.max(r1, r2)) // last modify by deeka //动态控制精度长度
	n = r1 >= r2 ? r1 : r2
	return ((arg1 * m - arg2 * m) / m).toFixed(n)
}
export const callStatusList = ['成功回访', '停机关机', '无人接听', '用户正忙', '暂无服务', '直接挂掉']

export const sipPhoneCode = {
	'00021': '1', // 亿彩
	'00018': '2', // 如意
	'00019': '2', // 非凡
	'00011': '2', // 新太阳城
	'00014': '2', // 元宝
	'00009': '2', // 巨人
	'00002': '2', // 金樽
	'00013': '1', // 英皇
	'00012': '1', // 新葡京
	'00010': '1', // 太阳城
	'00003': '1', // 金沙
	'00005': '1', // 亚联
	'00008': '1', // 亚投
	'00007': '1', // 亚冠
	'00006': '1', // 亦博
	'00004': '1', // 万博
	'00016': '2', // 澳亚
	'00020': '2', // 龙门
	'00001': '1', // 测试1
	10001: '1', // 测试2  // http://34.80.35.227:8090   35.194.147.223       // 测试  // http://34.80.35.227:8090   35.194.147.223
	// '10000': 'http://dev.test/', // 本地
	10002: '1' // 本地1
}

export const getAllPeriod = (str) => {
	let startTime // 开始时间
	let endTime // 结束时间
	let _today = new Date()
	// 一天时间的秒数
	let millisecond = 1000 * 60 * 60 * 24
	// 获取当天是本周中的第几天  0 表示是本周的第一天 也就是星期天
	let n = _today.getDay()
	let year = _today.getFullYear() // 当前年份
	let month = _today.getMonth() // 当前月份
	switch (str) {
		case 'today': // 今日
			startTime = _today.toLocaleDateString()
			endTime = _today.toLocaleDateString()
			return [startTime, endTime].map((_) => {
				return _.replace(/\//g, '-')
			})
		case 'yesterday': // 昨日
			startTime = new Date(_today.getTime() - millisecond).toLocaleDateString()
			endTime = new Date(_today.getTime() - millisecond).toLocaleDateString()
			return [startTime, endTime].map((_) => {
				return _.replace(/\//g, '-')
			})
		case 'this_week': // 本周
			startTime = new Date(_today.getTime() - millisecond * n).toLocaleDateString()
			endTime = _today.toLocaleDateString()
			return [startTime, endTime].map((_) => {
				return _.replace(/\//g, '-')
			})
		case 'last_week': // 上周
			startTime = new Date(_today.getTime() - millisecond * (n + 7)).toLocaleDateString()
			endTime = new Date(_today.getTime() - millisecond * (n + 1)).toLocaleDateString()
			return [startTime, endTime].map((_) => {
				return _.replace(/\//g, '-')
			})
		case 'this_month': // 本月
			startTime = new Date(year, month, 1).toLocaleDateString()
			endTime = _today.toLocaleDateString()
			return [startTime, endTime].map((_) => {
				return _.replace(/\//g, '-')
			})
		case 'last_month': // 上月
			if (month - 1 === 0) {
				year = year - 1
				month = 12
			}
			startTime = new Date(year, month - 1, 1).toLocaleDateString()
			endTime = new Date(year, month, 0).toLocaleDateString()
			return [startTime, endTime].map((_) => {
				return _.replace(/\//g, '-')
			})
		case 'last_two_month': // 近两月
			startTime = new Date(_today.getTime() - millisecond * 60).toLocaleDateString()
			endTime = _today.toLocaleDateString()
			return [startTime, endTime].map((_) => {
				return _.replace(/\//g, '-')
			})
		default:
			break
	}
}

/**
 * @description 将秒数转换成00:00:00
 * @param {Number} num > 0
 */
export const setSecondToFormat = (num) => {
	let h = Math.floor(num / 3600) < 10 ? `0${Math.floor(num / 3600)}` : Math.floor(num / 3600)
	let m = Math.floor((num / 60) % 60) < 10 ? `0${Math.floor((num / 60) % 60)}` : Math.floor((num / 60) % 60)
	let s = Math.floor(num % 60) < 10 ? `0${Math.floor(num % 60)}` : Math.floor(num % 60)
	return `${h}:${m}:${s}`
}

export const getDate = (dateTime, startType = 'year') => {
	const d = new Date(dateTime)
	const year = d.getFullYear()
	const month = getHandledValue(d.getMonth() + 1)
	const date = getHandledValue(d.getDate())
	const hours = getHandledValue(d.getHours())
	const minutes = getHandledValue(d.getMinutes())
	const second = getHandledValue(d.getSeconds())
	let resStr = ''
	if (startType === 'year') {
		resStr = year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + second
	} else {
		resStr = month + '-' + date + ' ' + hours + ':' + minutes
	}
	return resStr
}

export const getHandledValue = (num) => {
	return num < 10 ? '0' + num : num
}

export const localRead = (key) => {
	return localStorage.getItem(key) || ''
}
export const localSave = (key, value) => {
	localStorage.setItem(key, value)
}
export const requireParam = (obj) => {
	var rangeArr = [],
		param = ''
	if (obj && typeof obj === 'object') {
		if (rangeArr.length == 0) {
			rangeArr.push('?')
		}
		for (let i in obj) {
			// eslint-disable-next-line no-prototype-builtins
			if (obj.hasOwnProperty(i)) {
				if (obj[i] !== '') {
					rangeArr.push(i)
					rangeArr.push('=')
					rangeArr.push(obj[i])
					rangeArr.push('&')
				}
			}
		}
		param = rangeArr.join('').replace(/&$/, '')
		return param
	}
}
