import fetch from '@/libs/fetch'

export default class Pay {
	/**
	 * @description 充值
	 * @param {string} amount 充值金额
	 * @param {string} channel 支付方式 1为支付宝，2为微信
	 */
	static getOrder(data) {
		return fetch.post('/pays/get_order', data)
	}
	/**
	 * @description 获取账户余额
	 */
	static querySystemBanlance() {
		return fetch.get('/pays/get_system')
	}
	/**
	 * @description 转入钱包
	 * @param {string} amount 金额
	 * @param {string} opt    转入渠道（sms为短信，dial为电话）
	 */
	static transferWallet(data) {
		return fetch.post('/pays/wallet', data)
	}
	/**
	 * @description 充值记录
	 * @param {string} manager_name 用户名
	 * @param {array} created_at  日期
	 * @param {number} page 页码
	 * @param {number} per_page 条数
	 */
	static queryChargeRecord(params) {
		return fetch.get('/pays/pay_log', { params })
	}
	/**
	 * @description 转账记录
	 * @param {string} opt  转入账户
	 * @param {array} created_at  日期
	 * @param {number} page 页码
	 * @param {number} per_page 条数
	 */
	static queryTransferRecord(params) {
		return fetch.get('/pays/wallet_log', { params })
	}
	/**
	 * @description 查询订单状态
	 * @param {string} outTradeNo  订单号
	 */
	static checkPayStatus(outTradeNo) {
		return fetch.get(`/pays/check_order/${outTradeNo}`)
	}
}
