import fetch from '@/libs/fetch'

export default class SMSTemplate {
	// 获取所有激活的渠道
	static getAllTemplate() {
		return fetch({
			url: '/smstemplate/getalltemplate',
			method: 'patch'
		})
	}
	// 获取渠道
	static getSMSTemplate(params) {
		return fetch({
			url: '/smstemplate',
			method: 'get',
			params
		})
	}
	// 新增渠道获取
	static addSMSTemplate(data) {
		return fetch({
			url: '/smstemplate',
			method: 'post',
			data
		})
	}
	// 修改渠道
	static updateTemplate(data) {
		return fetch({
			url: `/smstemplate/${data.id}`,
			method: 'post',
			data
		})
	}
	// 删除渠道
	static deleteSMSTemplate(id) {
		return fetch({
			url: `/smstemplate/${id}`,
			method: 'delete'
		})
	}
	// SMS渠道明细
	static getSMSTemplateDetail(id) {
		return fetch({
			url: `/smstemplate/${id}`,
			method: 'get'
		})
	}
	// SMS类型
	static getSMSType() {
		return fetch({
			url: '/commons/getsmsType',
			method: 'get'
		})
	}
	// 获取当前用户模板
	static getMyTemplate() {
		return fetch({
			url: '/smstemplate/my',
			method: 'patch'
		})
	}
}
