<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: 'dotclouds-ele'
}
</script>

<style>
/* CSS */
html,
body {
	width: 100%;
	height: 100%;
	overflow: hidden;
	/* background: red; */
}
#app {
	width: 100%;
	height: 100%;
	border: 1px solid #2d8cf0;
}
* {
	padding: 0;
	margin: 0;
}
.drawer-footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	border-top: 1px solid #e8e8e8;
	padding: 10px 16px;
	text-align: right;
	background: #fff;
}
</style>
