<template>
	<div class="ele-main">
		<eleHead />
		<div class="ele-content">
			<router-view></router-view>
		</div>
	</div>
</template>
<script>
import eleHead from '../head/head.vue'
// import eleMenu from '../menu/menu.vue'

export default {
	components: { eleHead },
	data() {
		return {}
	}
}
</script>
<style lang="less" scoped>
.ele-main {
	width: 100%;
	height: 100vh;
}
.ele-content {
	width: 100%;
	height: calc(~'100% - 60px');
	display: flex;
	overflow: hidden;
	&-view {
		width: 100%;
		height: 100%;
		// padding: 10px;
		// overflow: hidden;
	}
}
</style>
