/*
 * @Author: duck
 * @Date: 2023-02-24 15:17:04
 * @LastEditors: duck
 * @LastEditTime: 2023-03-26 12:33:20
 * @Description: file content
 */
import fetch from '@/libs/fetch'

export default class groupCall {
	//获取呼叫名单
	static getCalleeList(data) {
		return fetch({
			url: '/okcc/getCalleeList',
			method: 'post',
			data
		})
	}
	//获取呼叫方式
	static getSupportMode(data) {
		return fetch({
			url: '/okcc/getSupportMode',
			method: 'post',
			data
		})
	}
	//获取班组
	static getAgentGroupList(data) {
		return fetch({
			url: '/okcc/getAgentGroupList',
			method: 'post',
			data
		})
	}
	//获取主叫分组
	static getCIDGroup(data) {
		return fetch({
			url: '/okcc/getCIDGroup',
			method: 'post',
			data
		})
	}
	//获取账号列表
	static getAccount(data) {
		return fetch({
			url: '/okcc/getAccount',
			method: 'post',
			data
		})
	}
	//登录
	static okccLogin(data) {
		return fetch({
			url: '/okcc/login',
			method: 'post',
			data
		})
	}

	//获取列表前，需要先请求一下这个接口
	static getListCallTask(data) {
		return fetch({
			url: '/okcc/callTask',
			method: 'post',
			data
		})
	}
	// 列表
	static getList(data) {
		return fetch({
			url: 'okcc/callTask_query',
			method: 'post',
			data
		})
	}
	//新增修改任务保存提交
	static callTaskEdit(data) {
		return fetch({
			url: 'okcc/callTask_edit',
			method: 'post',
			data
		})
	}
	//保存呼叫名单接口
	static calleeListEdit(data) {
		return fetch({
			url: 'okcc/calleeList_edit',
			method: 'post',
			data
		})
	}
	//服务端上传文件
	static NewuploadFile(data) {
		return fetch({
			url: 'okcc/NewuploadFile',
			method: 'post',
			data
		})
	}
	//删除
	static callTaskDelete(data) {
		return fetch({
			url: 'okcc/callTask_delete',
			method: 'post',
			data
		})
	}
	//启动
	static callTaskStartTask(data) {
		return fetch({
			url: 'okcc/callTask_startTask',
			method: 'post',
			data
		})
	}
	//暂停
	static callTaskStopTask(data) {
		return fetch({
			url: 'okcc/callTask_stopTask',
			method: 'post',
			data
		})
	}
	//详情
	static callTaskGet(data) {
		return fetch({
			url: 'okcc/callTask_get',
			method: 'post',
			data
		})
	}
	//群呼状态
	static queryAgents(data) {
		return fetch({
			url: 'okcc/queryAgents',
			method: 'post',
			data
		})
	}
	//群呼状态修改
	static okccUpdateStatus(data) {
		return fetch({
			url: 'okcc/updateStatus',
			method: 'post',
			data
		})
	}
	//查询重呼功能
	static getCallTaskMode(params) {
		// return fetch({
		// 	url: 'okcc/getCallTaskMode',
		// 	method: 'get',
		//   {data}
		// })
		return fetch.get('okcc/getCallTaskMode', {
			params
		})
	}
	//重呼提交功能
	static callTaskRecall(data) {
		return fetch({
			url: 'okcc/callTask_recall',
			method: 'post',
			data
		})
	}
}
