/*
 * @Author: duck
 * @Date: 2023-01-31 16:39:12
 * @LastEditors: duck
 * @LastEditTime: 2023-04-28 19:16:45
 * @Description: file content
 */
export default {
	baseUrl: {
		dev: 'https://wb.snakejoy.org/', // https://35.194.147.223/
		pro: {
			'00047': { url: 'https://crm.dev.fqmgl.com/', name: 'newcrm' },
			'00046': { url: 'https://dryc.snakejoy.org/', name: '亿彩-电销' },
			'00045': { url: 'https://dxhtxpj.snakejoy.org/', name: '宏图-电销' },
			'00044': { url: 'https://agsikwin.snakejoy.org/', name: 'ag-sikwin' },
			'00043': { url: 'https://drwb.snakejoy.org/', name: '万博-电销' },
			'00042': { url: 'https://hth.snakejoy.org/', name: 'HTH' },
			'00041': { url: 'https://drhth.snakejoy.org/', name: 'HTH-电销' },
			'00040': { url: 'https://dryt.snakejoy.org/', name: '亚投-电销' },
			'00039': { url: 'https://dryg.snakejoy.org/', name: '亚冠-电销' },
			'00038': { url: 'https://drsikwin.snakejoy.org/', name: 'sikwin-dw' },
			'00037': { url: 'https://drsikwin.testcrm.snakejoy.org/', name: 'sikwin-dw-test' },
			'00036': { url: 'https://yc.testcrm.snakejoy.org:11082/', name: '亿彩测试' },
			'00035': { url: 'https://ht.testcrm.snakejoy.org:11081/', name: '宏图测试' },
			'00034': { url: 'https://sikwin.snakejoy.org/', name: 'sikwin' },
			'00033': { url: 'https://drxy.snakejoy.org/', name: 'drxy' },
			'00032': { url: 'https://csdr.snakejoy.org/', name: 'csdr' },
			'00031': { url: 'https://ty.snakejoy.org/', name: '51体育' },
			'00030': { url: 'https://htxpj.snakejoy.org/', name: '宏图' },
			'00029': { url: 'https://pj1.snakejoy.org/', name: '葡京1号' },
			'00028': { url: 'https://wns.snakejoy.org/', name: '威尼斯人' },
			'00027': { url: 'https://yd.snakejoy.org/', name: '澳門新葡京' },
			'00026': { url: 'https://amxpj.snakejoy.org/', name: '澳門新葡京' },
			'00021': { url: 'https://yc.snakejoy.org/', name: '亿彩' },
			'00020': { url: 'https://longmen.snakejoy.org/', name: '龙门' },
			'00019': { url: 'https://ff.snakejoy.org/', name: '非凡' },
			'00018': { url: 'https://ruyi.snakejoy.org/', name: '如意' },
			'00016': { url: 'https://aoya.snakejoy.org/', name: '澳亚' },
			'00015': { url: 'https://testdrxy.snakejoy.org/', name: 'testdrxy' },
			'00014': { url: 'https://yuanbao.snakejoy.org/', name: '元宝' },
			'00013': { url: 'https://yh.snakejoy.org/', name: '英皇' },
			'00012': { url: 'https://pj.snakejoy.org/', name: '新葡京' },
			'00011': { url: 'https://taiyangcheng.snakejoy.org/', name: '新太阳城' },
			'00010': { url: 'https://tyc.snakejoy.org/', name: '太阳城' },
			'00009': { url: 'https://juren.snakejoy.org/', name: '巨人' },
			'00008': { url: 'https://yt.snakejoy.org/', name: '亚投' },
			'00007': { url: 'https://yg.snakejoy.org/', name: '亚冠' },
			'00006': { url: 'https://yb.snakejoy.org/', name: '亦博' },
			'00005': { url: 'https://yl.snakejoy.org/', name: '亚联' },
			'00004': { url: 'https://wb.snakejoy.org/', name: '万博' },
			'00003': { url: 'https://js.snakejoy.org/', name: '金沙' },
			'00002': { url: 'https://jz.snakejoy.org/', name: '金樽' },
			'00001': { url: 'http://api.test/', name: 'test' }
		}
	},
	uploadUrl: 'https://34.80.35.227:8700/'
}
