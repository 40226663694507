<template>
	<div>
		<Dropdown trigger="click" @on-click="selectLang">
			<div style="color: #fff">
				{{ title }}
				<Icon :size="18" type="md-arrow-dropdown" color="#fff" />
			</div>
			<DropdownMenu slot="list">
				<DropdownItem v-for="(value, key) in localList" :name="key" :key="`lang-${key}`">{{ value }}</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	</div>
</template>

<script>
export default {
	name: 'Language',
	props: {
		lang: String
	},
	data() {
		return {
			langList: {
				'zh-CN': '语言',
				'en-US': 'Language'
			},
			localList: {
				'zh-CN': '中文简体',
				'en-US': 'English'
			}
		}
	},
	watch: {
		lang(lang) {
			this.$i18n.locale = lang
		}
	},
	computed: {
		title() {
			return this.langList[this.lang]
		}
	},
	methods: {
		selectLang(name) {
			//  强制刷新，防止table的数据没有翻译
			window.location.reload()
			this.$emit('on-lang-change', name)
		}
	}
}
</script>
