import fetch from '@/libs/fetch'

export default class UploadService {
	/**
	 * @description 导入和添加任务
	 * @param {string} file_path v1/upload API 返回的上传文件的路径
	 */
	static addFileToQueue(file_path) {
		return fetch.post('/imports/add', { file_path })
	}
	/**
	 * @description 列出导入的记录
	 * @param {int} page 当前页码
	 * @param {int} per_page 每页记录
	 */
	static getImportedRecordsList(params) {
		return fetch.get('/imports/list', { params })
	}
}
