const state = {
	loginStatus: false, // SIP程序登录状态
	callData: [], // 队列会员数据
	currentCallStatus: '',
	currentIndex: 0
}

const mutations = {
	SET_Login_STATUS(state, status) {
		state.loginStatus = status
	},
	SET_CALL_DATA(state, arr) {
		state.callData = JSON.parse(JSON.stringify(arr))
	},
	ADD_CALL_DATA(state, call) {
		if (call instanceof Array) {
			let arr = JSON.parse(JSON.stringify(state.callData))
			// state.callData = arr.concat(call)
			state.callData = [...arr, ...call]
		} else {
			state.callData.push(call)
		}
	},
	DELETE_CALL_DATA(state, index) {
		state.callData.splice(index, 1)
	}
}

export default { state, mutations }
