import { setToken, getToken, localSave, localRead } from '@/libs/utils'
import User from '@/api/user'
import Pay from '@/api/pay'
import SmsTemplate from '@/api/sms-template'
const state = {
	name: '',
	channel_route_id: '',
	login_ip: '',
	login_time: '',
	avatar: '',
	token: getToken(),
	access: [],
	is_department_leader: 0, // 角色权限 0成员 1主管 2转化专员
	department_id: null, // 所属部门id
	balance: 0, // 主账户余额
	dial_balance: 0, // 电话余额
	sms_balance: 0, // 短信余额
	smsType: '', // 短信类型
	reptileList: [], // 爬虫列表数据
	isGbk: false, // bb 显示  gbk 不显示
	sipInfo: {},
	hasGetInfo: false,
	local: localRead('local'),
	smsTemplate: []
}

// const getters = {
//   reptileColor (state) {
//     let reptile = state.reptileList.map(e => e.status)
//     if (reptile.includes(1)) {
//       return '#ed4014'
//     } else if (reptile.includes(2)) {
//       return '#ff9900'
//     } else {
//       return '#19be6b'
//     }
//   }
// }

const mutations = {
	setBaseInfo(state, info) {
		state.name = info.name
		state.channel_route_id = info.channel_route_id
		state.login_ip = info.login_ip
		state.login_time = info.login_time
		state.department_id = info.department_id
		state.sipInfo = info.sip_arr
		state.is_department_leader = info.is_department_leader
		state.access = info.roles[0].permissions.map((_) => {
			return _.name
		})
		// state.smsType = info.system_setting.sms.type
	},
	setToken(state, data) {
		state.token = data.access_token
		setToken(data.access_token, data.expires_in)
	},
	setStoreToken(state, token) {
		state.token = token
	},
	setHasGetInfo(state, status) {
		state.hasGetInfo = status
	},
	setBalance(state, data) {
		state.balance = data.balance
		state.dial_balance = data.dial_balance
		state.sms_balance = data.sms_balance
	},
	setreptile(state, data) {
		state.reptileList = data.spider
		if (data.host_type === 'bb') {
			state.isGbk = true
		} else {
			state.isGbk = false
		}
	},
	setLocal(state, lang) {
		localSave('local', lang)
		state.local = lang
	},
	setSMSTemplate(state, template) {
		state.smsTemplate = template
	}
}

const actions = {
	// 登录
	handleLogin({ commit }, { name, password }) {
		name = name.trim()
		return new Promise((resolve, reject) => {
			User.login({
				name,
				password
			})
				.then((res) => {
					if (res.code === 200) {
						commit('SET_CALL_DATA', [])
						window.localStorage.removeItem('vuex')
						let result = res.data
						commit('setToken', result)
						resolve()
					}
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	// 获取用户信息
	getUserInfo({ commit, dispatch }) {
		return new Promise((resolve, reject) => {
			User.getUserInfo()
				.then((res) => {
					commit('setBaseInfo', res.data)
					commit('setHasGetInfo', true)
					resolve(res.data)
					dispatch('getMySmsTemplate')
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	// 获取当前用户短信模板
	getMySmsTemplate({ commit }) {
		return new Promise((resolve, reject) => {
			SmsTemplate.getMyTemplate()
				.then((res) => {
					commit('setSMSTemplate', res.data)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	// 获取余额信息
	queryBalance({ commit }) {
		return new Promise((resolve) => {
			Pay.querySystemBanlance().then((res) => {
				commit('setBalance', res.data)
				resolve(res)
			})
		})
	},
	// 获取爬虫列表
	getReptileList({ commit }) {
		return new Promise((resolve, reject) => {
			User.getReptileList()
				.then((res) => {
					resolve(res.data)
					commit('setreptile', res.data)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}
}

export default {
	state,
	// getters,
	mutations,
	actions
}
