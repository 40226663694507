import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design'
import dayjs from 'dayjs'
import i18n from '@/locale'
import './index.less'
import './assets/icon/iconfont.css'
import './assets/style/common.css'
import importDirective from '@/directive'
import Viewer from 'v-viewer' //点击图片大图预览
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
	//默认样式，可以按需求更改
	Options: {
		inline: true,
		button: true,
		navbar: true,
		title: true,
		toolbar: true,
		tooltip: true,
		movable: true,
		zoomable: true,
		rotatable: true,
		scalable: true,
		transition: true,
		fullscreen: true,
		keyboard: true,
		url: 'data-source'
	}
})
Vue.config.productionTip = false
Vue.prototype.$dayjs = dayjs
Vue.use(ViewUI)
importDirective(Vue)
new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App)
}).$mount('#app')
