import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import modules from './modules'

Vue.use(Vuex)
const persistedState = createPersistedState({
	paths: ['call.callData']
})
export default new Vuex.Store({
	modules,
	plugins: [persistedState],
	strict: process.env.NODE_ENV !== 'production'
})
