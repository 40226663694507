/*
 * @Author: duck
 * @Date: 2023-01-31 16:39:12
 * @LastEditors: duck
 * @LastEditTime: 2023-03-16 21:19:02
 * @Description: file content
 */
import fetch from '@/libs/fetch'

export default class Number {
	/**
	 * @description 导入列表
	 * @param {int} task_id data.task_assigns.task_id，从 /api/v1/imports/list 返回
	 */
	static getAssignmentsList(params) {
		return fetch({
			url: '/imports/assign',
			method: 'get',
			params
		})
	}
	/**
	 * @description 号码导入的详情
	 * @param {int} task_id data.task_assigns.task_id，从 /api/v1/imports/list 返回
	 */
	static getImportsDetail(params) {
		return fetch({
			url: '/imports/imports/detail',
			method: 'post',
			params
		})
	}

	// 会员导入
	static importMember(params) {
		return fetch({
			url: '/imports/add',
			method: 'post',
			params
		})
	}
	// 导入列表
	static getImportList(params) {
		return fetch({
			url: '/imports/list',
			method: 'get',
			params
		})
	}

	/**
	 * @description 重新坐席分配
	 * @param {int} task_id data.task_assigns.task_id，从 /api/v1/imports/list 返回
	 * @param {array} assign [{ manager_id, assign_nums }]
	 */
	static reassignTasksToManagers(data) {
		return fetch({
			url: '/imports/reassign',
			method: 'post',
			data
		})
	}
	//失败数据导出
	static exportFailData(data) {
		return fetch({
			url: '/imports/export',
			method: 'post',
			data
		})
	}
	// 全部数据导出
	static exportAllData(data) {
		return fetch({
			url: '/imports/export/all',
			method: 'post',
			data
		})
	}
	// 数据下载
	static dataDownload(data) {
		return fetch({
			url: '/imports/datadownload',
			method: 'post',
			data
		})
	}
	// 导入批次下载
	static datadownload_exportAll(data) {
		return fetch({
			url: '/imports/export/datadownload_exportAll',
			method: 'post',
			data
		})
	}
	//批量分配查询
	static getbatchAssignList(params) {
		return fetch({
			url: '/imports/batchassignquery',
			method: 'post',
			params
		})
	}
	//批量分配
	static batchReAssign(data) {
		return fetch({
			url: '/imports/batchreassign',
			method: 'post',
			data
		})
	}
	
	//获取所有未分配的TaskId
	static getTaskAll(params) {
		return fetch({
			url: '/imports/gettaskall',
			method: 'get',
			params
		})
	}
	//获取所有未分配来源
	static getRegFromWhereAll(params) {
		return fetch({
			url: '/imports/getregfromwhereall',
			method: 'get',
			params
		})
	}
	//获取所有地区
	static getAreaAll(params) {
		return fetch({
			url: '/imports/getareaall',
			method: 'get',
			params
		})
	}
}
